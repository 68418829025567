import { txTypes } from '$state/queries/auth';
import { referralsEnabled } from '$state/queries/client';
import { PatientStatus } from '$state/types';
import { Failed } from '$ui/Shared';
import { useAppDispatch, useAppSelector, useSources } from '@/state';
import {
    fetchPatient,
    label,
    savePatient,
    unlabel,
} from '@/state/concerns/patient';
import * as patients from '$state/queries/patient';
import * as React from 'react';
import styled from 'styled-components';
import { NextActions } from '../Actions/NextActions';
import { Attributes, Pad } from './Attributes';
import { LoadingAttributes } from './LoadingAttributes';
import { Integrations } from './Integrations';
import { IntegrationPanel } from './IntegrationPanel';
import { withState } from '$state/utils';
import { featureEnabled } from '$state/queries/features';
import * as dentally from '$state/concerns/dentally/patients';
import {
    selectClientCountry,
    getAccountLabels,
    getRecentlyUsedLabels,
} from '$state/concerns/client';

import { mix } from '$ui/Flo/util';
import { Labels } from './Labels';
import { WakeUp } from '$ui/Snooze/WakeUp';

interface Props {
    id: string;
    withoutSummary?: boolean;
    lite?: boolean; // Removes less-important details, such as address
    children?: React.ReactNode;
    barPosition?: 'left' | 'right';
}

/**
 * Memoized because this is quite a large component tree and is used in
 * several places where re-renders are common.
 */
export const Patient = React.memo(
    ({ id, withoutSummary, lite, children, barPosition = 'left' }: Props) => {
        const dispatch = useAppDispatch();
        const availableTypes = useAppSelector(txTypes);
        const availableSources = useSources();
        const refEnabled = useAppSelector(referralsEnabled);
        const state = useAppSelector(patients.get(id));

        const clientCountry = useAppSelector(withState(selectClientCountry));
        const accountLabels = useAppSelector(withState(getAccountLabels));
        const recentlyUsedLabels = useAppSelector(
            withState(getRecentlyUsedLabels),
        );
        const changeStageEnabled = useAppSelector(
            withState(featureEnabled, 'change-stage'),
        );

        const [editing, setEditing] = React.useState(false);
        const [panel, setPanel] = React.useState({
            dentally: false,
        });

        // handle panel state
        const activePanel = Object.keys(panel).find((key) => panel[key]);

        const onSelect = (integration: string) => {
            // set all panel states to false
            Object.keys(panel).forEach((key) => {
                setPanel({ ...panel, [key]: false });
            });
            setPanel({ ...panel, [integration]: !panel[integration] });
        };

        // default data
        React.useEffect(() => {
            dispatch(fetchPatient(id));
        }, [id]);

        // begin load dentally data
        const dentallyState = useAppSelector(
            withState(dentally.selectPatient, id),
        );

        React.useEffect(() => {
            if (dentally.disabled(dentallyState)) {
                return;
            }

            if (dentally.idle(dentallyState)) {
                dispatch(dentally.get(id));
            }
        }, [dentallyState.state, id]);

        if (state.status === PatientStatus.ERRORED) {
            return <Failed />;
        }

        if (!patients.isLoaded(state)) {
            return (
                <Wrapper barPosition={barPosition}>
                    <Integrations
                        id={id}
                        position={barPosition}
                        onSelect={(integration) => onSelect(integration)}
                        selected={activePanel ?? ''}
                    />
                    <Layout stretch>
                        <LoadingAttributes lite={lite} />
                    </Layout>
                </Wrapper>
            );
            // TODO: Have buttons have a separate loading state rather
            // than using new lead's buttons?
        }

        const disabled =
            state.status == PatientStatus.SAVING ||
            state.status == PatientStatus.ERRORED_SAVING_ACTION ||
            editing;

        const handleClose = () => {
            if (activePanel) {
                setPanel({ ...panel, [activePanel]: false });
            }
        };

        const handleUnlabel = (value: string) => {
            dispatch(unlabel({ patientId: id, label: value }));
        };

        const handleLabel = (value: string) => {
            dispatch(label({ patientId: id, label: value }));
        };

        const isWakingUp = state.patient.snooze?.state === 'waking';

        return (
            <Wrapper barPosition={barPosition}>
                {isWakingUp && <Overlay />}
                <Integrations
                    id={id}
                    position={barPosition}
                    onSelect={(integration) => onSelect(integration)}
                    selected={activePanel ?? ''}
                />
                <Layout>
                    {activePanel && (
                        <PanelWrapper>
                            <IntegrationPanel
                                integration={activePanel}
                                dentally={{
                                    id: id,
                                    onClose: () => handleClose(),
                                }}
                            />
                        </PanelWrapper>
                    )}
                    {children && <HeaderWrapper>{children}</HeaderWrapper>}
                    <PatientInfo>
                        <AttributesWrapper>
                            <Attributes
                                dentallyState={dentallyState}
                                state={state.status}
                                details={state.patient}
                                country={clientCountry}
                                availableTypes={availableTypes}
                                detailsChanged={(details) =>
                                    dispatch(savePatient(details))
                                }
                                onEdit={(status) => setEditing(status)}
                                lite={lite}
                                availableSources={availableSources}
                                referralsEnabled={refEnabled}
                                changeStageEnabled={changeStageEnabled}
                            />
                        </AttributesWrapper>
                        {isWakingUp && (
                            <WakeUp
                                id={state.patient.id}
                                firstName={state.patient.first_name}
                                lastName={state.patient.last_name}
                                snooze={state.patient.snooze}
                            />
                        )}
                        {!isWakingUp && (
                            <>
                                <LabelWrapper>
                                    <Pad scalar={[2, 3]}>
                                        <Labels
                                            key={state.patient.id}
                                            onDelete={(label) =>
                                                handleUnlabel(label)
                                            }
                                            labels={state.patient.labels}
                                            recentLabels={recentlyUsedLabels}
                                            accountLabels={accountLabels}
                                            onAdd={(label) =>
                                                handleLabel(label)
                                            }
                                        />
                                    </Pad>
                                </LabelWrapper>
                                <NextActionsWrapper>
                                    <NextActions
                                        id={state.patient.id}
                                        stage={state.patient.stage}
                                        date={state.patient.next_action_at}
                                        disabled={disabled}
                                        withoutSummary={withoutSummary}
                                    />
                                </NextActionsWrapper>
                            </>
                        )}
                    </PatientInfo>
                </Layout>
            </Wrapper>
        );
    },
);

const Overlay = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #00000032;
    z-index: 100;
`;

const LabelWrapper = styled.div`
    position: relative;
    border-top: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
`;

Patient.displayName = 'Patient';

const Wrapper = styled.div<{ barPosition: 'left' | 'right' }>`
    display: flex;
    flex-direction: ${({ barPosition }) =>
        barPosition === 'left' ? 'row' : 'row-reverse'};
    height: 100%;
    width: 100%;
`;

const Layout = styled.div<{ stretch?: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    ${({ stretch }) => stretch && 'justify-content: space-between'};
    position: relative;
    overflow: hidden;
`;

const PatientInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
`;

const AttributesWrapper = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const NextActionsWrapper = styled.div`
    width: 100%;
`;

const HeaderWrapper = styled.div``;

const PanelWrapper = styled.div`
    z-index: 500;
    height: 100%;
    width: 100%;
    position: absolute;
`;
