import React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import menuButton from './images/menu-button.png';
import modal from './images/modal.png';

export const ChangeStageInstructions = () => {
    return (
        <Wrapper>
            <h2>Change Stage has moved!</h2>
            <p>
                We have made the ‘Change stage’ tool easier to use and moved
                this so each patient can be handled without disrupting your
                workflow. Watch the video below to see how it works or read the
                instructions below.
            </p>
            <Video>
                <iframe
                    src="https://player.vimeo.com/video/1010209766?h=5e465c8bf2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    frameBorder="0"
                ></iframe>
            </Video>
            <h3>Instructions</h3>
            <p>
                To change a patient stage, next to the ‘Edit patient‘ button you
                will see a new ‘...’ button.
            </p>
            <img src={menuButton} alt="Menu Button" />
            <p>
                Click the button to open up the menu, here you will see the
                ‘Change Stage’ button. Clicking the button will open a popup.
            </p>
            <p>
                You can see the current stage the patient is at, and a list of
                new stages they can be moved to and a note field for information
                about the stage move.
            </p>
            <img src={modal} alt="Change Stage modal" />
            <p>
                Feedback is appreciated. Email{' '}
                <a href="mailto:dev@leadflo.com">dev@leadflo.com</a> or click
                the chat icon to the bottom left of the screen.
            </p>
            <p>
                We are available via Live Chat Mon-Fri 9:30am to 5pm. For out of
                hours, you can submit the form and we will get back to you next
                working day.
            </p>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'body2' })};
    max-width: 800px;
    margin: 0 auto;
    img {
        max-width: 100%;
        margin: 0 auto ${mix.unit({ size: 2 })};
    }
`;
const Video = styled.div`
    width: 100%;
    height: 0;
    max-width: 100%;
    padding: 56.25% 0 0 0;
    position: relative;
    margin: ${mix.unit({ size: 2 })} auto;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
