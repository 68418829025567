import React, { useState } from 'react';
import { Header } from '../Header';
import { useHistory } from 'react-router-dom';
import { useAppSelector, withState } from '$state';
import { referralsEnabled } from '$state/queries/client';
import {
    subscriberHash as subscriberHashQuery,
    subscriberId as subscriberIdQuery,
} from '$state/queries/auth';
import { novuAppId } from '$state/queries/config';
import * as patients from '$state/concerns/patient';
import { useAppDispatch } from '$state/hooks';
import { z } from 'zod';
import { IMessage } from '@novu/notification-center';
import { featureEnabled } from '$state/queries/features';
import { Slideout } from '$ui/Shared/Slideout';
import { ConnectedAddPatientForm } from '$ui/AddPatient/AddPatientForm';
import { ConfirmationModal } from '$ui/Flo/ConfirmationModal';

const PatientPayload = z.object({
    patientId: z.string(),
});

type ModalState = 'closed' | 'clean' | 'dirtied' | 'confirming';

export const RoutingHeader = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const addEnabled = !useAppSelector(referralsEnabled);
    const subscriberId = useAppSelector(subscriberIdQuery);
    const subscriberHash = useAppSelector(subscriberHashQuery);
    const appId = useAppSelector(novuAppId);
    const newAddPatientEnabled = useAppSelector(
        withState(featureEnabled, 'add-patient-form'),
    );
    const [modalState, setModalState] = useState<ModalState>('closed');

    const logoClicked = () => {
        history.push('/');
    };

    const addPatientClicked = () => {
        if (newAddPatientEnabled) {
            setModalState('clean');
            return;
        }

        history.push('/patients/add');
    };

    const onNotificationClick = (message: IMessage): void => {
        const payload = PatientPayload.parse(message.payload);
        dispatch(
            patients.openPatient({
                patientId: payload.patientId,
            }),
        );
    };

    const onConfirmClose = React.useCallback(() => {
        if (modalState === 'dirtied') {
            setModalState('confirming');
            return;
        }

        setModalState('closed');
    }, [modalState]);

    return (
        <>
            <Header
                onLogoClicked={logoClicked}
                onAddPatientClicked={addPatientClicked}
                addEnabled={addEnabled}
                subscriberId={subscriberId}
                subscriberHash={subscriberHash}
                appId={appId}
                onNotificationClick={onNotificationClick}
            />
            <Slideout
                open={modalState !== 'closed'}
                onClose={() => onConfirmClose()}
                width={52.5}
            >
                {modalState !== 'closed' && (
                    <ConnectedAddPatientForm
                        onClose={() => onConfirmClose()}
                        onDirtied={() => setModalState('dirtied')}
                        onComplete={() => setModalState('closed')}
                    />
                )}
            </Slideout>
            {modalState === 'confirming' ? (
                <ConfirmationModal
                    icon={{ name: 'AlertCircle', hue: 'red' }}
                    title="Close and discard patient details?"
                    positiveButtonLabel="Yes, discard"
                    neutralButtonLabel="No, do not discard"
                    onConfirm={() => setModalState('closed')}
                    onCancel={() => setModalState('dirtied')}
                >
                    Closing this dialog will discard all of the details you have
                    entered.
                </ConfirmationModal>
            ) : null}
        </>
    );
};
