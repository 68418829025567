import auth from './service';
import React, { ComponentType } from 'react';

export function withRequireAuth<T extends Record<string, any>>(
    Component: ComponentType<T>,
) {
    return (props: T) => (
        <RequireAuth>
            <Component {...props} />
        </RequireAuth>
    );
}

export function useAuthentication() {
    const [authed, setAuthed] = React.useState<boolean>(auth.authenticated());

    React.useEffect(() =>
        auth.onAuthStateChanged(() => {
            setAuthed(auth.authenticated());
        }),
    );

    return authed;
}

type RequireAuthProps = {
    children: React.ReactNode;
    onUnauthenticated?: () => void;
};

export function RequireAuth(props: RequireAuthProps) {
    const { children, onUnauthenticated = () => null } = props;

    const authed = useAuthentication();

    // bingo
    React.useEffect(() => {
        if (!authed) {
            onUnauthenticated();
        }
    }, [authed]);

    if (!authed) {
        return null;
    }

    return <>{children}</>;
}
