/**
 * Temporary TypeScript entrypoint
 *
 * Eventually, this file will be renamed `index.ts` and become the
 * entrypoint for the entire application.
 *
 * For now, this will be imported at the end of `index.js` so we can
 * incrementally port the entrypoint to TypeScript.
 */

// Legacy Styles
import 'angularjs-datepicker/dist/angular-datepicker.min.css';
import 'ui-select/dist/select.min.css';
import './scss/main.scss';

// Angular
import angular from 'angular';
import ngRedux from 'ng-redux';
import ngSanitize from 'angular-sanitize';
import ngAnimate from 'angular-animate';
import ngUiBootstrap from 'angular-ui-bootstrap';
import { name as ngStorage } from 'ngstorage';
import ngUiSelect from 'ui-select';
import ngPdf from 'angular-pdf';
import ngTextMask from 'angular1-text-mask';
import angularModalService from 'angular-modal-service';
import 'ngimport';
import 'angular-inflector';
import 'angularjs-datepicker';
import 'angular-utf8-base64';
import { angular2react } from 'angular2react';
import * as views from '@/bridge/views';

// React
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactApp } from './ReactApp';

// Legacy App
import appController from '@/app.controller';
import appRunner from '@/app.run';
import appConfigure from '@/app.config';
import appDirective from '@/app.directive';
import legacy from '@/app/index';
import v2 from '@/v2';
import bridge from '@/bridge';

// Legacy Libraries
import * as redux from 'redux';
import reduxThunk from 'redux-thunk';
import * as phone from 'libphonenumber-js';
import graphlib from 'graphlib';
import machina from 'machina';
import { createLogger } from 'redux-logger';

// Sentry
import * as ReactSentry from '@sentry/react';
import * as Integrations from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

// State
import { RootState, store } from '$state';
import { logout } from '$state/concerns/auth';
import { baseURL, proxyURL } from '$state/queries/config';
import { fetchConfig, refreshing, setApiVersion } from '$state/concerns/config';
import {
    conversationRead,
    conversationUnread,
    patientStarred,
    patientUnstarred,
} from '$state/concerns/inbox';
import {
    actionUpdated,
    actionWaking,
    commChangedState,
    communicationOccurred,
    formSubmitted,
    integrationStateChanged,
    integrationSyncStateChanged,
} from '$state/events';
import * as dentally from '$state/concerns/dentally/patients';
import { fetchCounts } from '$state/concerns/actions';
import { listSources } from '$state/concerns/sources';
import * as bulk from '$state/concerns/bulk';
import * as patients from '$state/concerns/patient';
import toast from 'react-hot-toast';
import { Notification } from '$ui/Notifications/Notification';
import { removeLabel } from '$state/concerns/client';
import { fetchTimeline } from '$state/concerns/timeline';

// Services
import auth from '$auth';
import versioning from '@/router/service';
import api from './api/api';

// Jquery + friends
import jQuery from 'jquery';
import 'jquery-mousewheel';

// Polyfills
import 'cross-fetch/polyfill';
import sockets from '@/sockets';
import { CommEventUnion, CommEventUnionInbound, Status } from '$types';
import { Observable } from 'rxjs';

// temporary until correct home exists
store.dispatch(fetchConfig());

// This should be refactored so that:
// 1. The payload is validated so it's 100% type-safe
// 2. The routes are defined else where in a more appropriate home
const observable = new Observable<RootState>((observer) => {
    return store.subscribe(() => {
        observer.next(store.getState());
    });
});

sockets.start(observable, {
    'patient.communications.changed-state': (msg) => {
        if (!msg.identity) {
            console.error(
                `Received patient.communications.changed-state without patientId`,
            );
            return;
        }
        const payload = msg.payload as CommEventUnion;
        // temporary until timeline v2 is ready - [sc-4334]
        const status: string =
            typeof payload.status === 'object'
                ? (payload.status as Status).name
                : payload.status;

        store.dispatch(
            commChangedState({
                patientId: msg.identity,
                communication: {
                    ...payload,
                    status: status || 'sent',
                },
                state: payload.status,
            }),
        );
    },

    'patient.opportunity.changed': (msg) => {
        const payload = msg.payload as {
            stage: string;
            next_action_at: string;
        };

        store.dispatch(
            actionUpdated({
                patientId: msg.identity,
                stage: payload.stage,
                nextActionAt: payload.next_action_at,
            }),
        );

        store.dispatch(fetchCounts([]));
    },

    'patient.communication.sent': (msg) => {
        const payload = msg.payload as CommEventUnion;
        // temporary until timeline v2 is ready - [sc-4334]
        const status: string =
            typeof payload.status === 'object'
                ? (payload.status as Status).name
                : payload.status;

        store.dispatch(
            communicationOccurred({
                patientId: msg.identity,
                communication: {
                    ...payload,
                    status: status || 'sent',
                } as CommEventUnion,
                inbound: false,
            }),
        );
    },

    'patient.communication.received': (msg) => {
        const payload = msg.payload as CommEventUnionInbound;
        const status: string =
            typeof payload.status === 'object'
                ? (payload.status as Status).name
                : payload.status;

        store.dispatch(
            communicationOccurred({
                patientId: msg.identity,
                communication: {
                    ...payload,
                    status: status || 'received',
                } as CommEventUnion,
                inbound: true,
            }),
        );
    },

    'form.submission.applied': ({ identity }) => {
        store.dispatch(formSubmitted(identity));
    },

    'conversation.read': ({ identity }) => {
        store.dispatch(
            conversationRead({ patientId: identity, notify: false }),
        );
    },

    'conversation.unread': ({ identity }) => {
        store.dispatch(
            conversationUnread({ patientId: identity, notify: false }),
        );
    },

    'patient.starred': ({ identity }) => {
        store.dispatch(
            patientStarred({
                patientId: identity,
                notify: false,
            }),
        );
    },

    'patient.unstarred': ({ identity }) => {
        store.dispatch(
            patientUnstarred({
                patientId: identity,
                notify: false,
            }),
        );
    },

    'patient.labels.cleanup': (msg) => {
        const payload = msg.payload as string;
        store.dispatch(removeLabel(payload));
    },

    'communication.batch.changed-state': (msg) => {
        const payload = msg.payload as string;
        store.dispatch(bulk.load(payload));
    },

    'notification.sms': ({ payload }) => {
        const {
            communication_id: id,
            patient_name: name,
            patient_id: patientId,
            date,
        } = payload as {
            communication_id: string;
            patient_name: string;
            patient_id: string;
            date: string;
        };

        toast.custom(
            ({ visible }) => {
                return React.createElement(Notification, {
                    visible,
                    icon: 'Smartphone',
                    title: name,
                    action: 'New SMS from',
                    time: date,
                    onView: () => {
                        store.dispatch(
                            patients.openPatient({
                                patientId,
                            }),
                        );
                        toast.dismiss(id);
                    },
                    onClose: () => toast.dismiss(id),
                });
            },
            {
                id,
                duration: Infinity,
                position: 'bottom-right',
            },
        );
    },

    'integrations.sync.state.changed': (msg) => {
        const payload = msg.payload as {
            id: string;
            client_id: number;
            integration: string;
            state: 'queued' | 'syncing' | 'failed' | 'complete';
            queued_at: string;
            syncing_at: string | null;
            completed_at: string | null;
            failed_at: string | null;
            error: string | null;
        };

        store.dispatch(integrationSyncStateChanged(payload));
    },

    'integrations.state.changed': (msg) => {
        const payload = msg.payload as {
            client_id: number;
            integration: string;
            state: 'disconnected' | 'healthy' | 'unhealthy';
        };

        store.dispatch(integrationStateChanged(payload));
        const { integration, state } = payload;

        const integrationCap =
            integration.charAt(0).toUpperCase() + integration.slice(1);

        if (state === 'unhealthy') {
            toast.error(
                () => {
                    return React.createElement(
                        'a',
                        {
                            href: '/settings/integrations/' + integration,
                            style: {
                                color: '#fff',
                                'font-weight': 'bold',
                            },
                        },
                        `Your ${integrationCap} integration is unhealthy. Go to ${integrationCap} settings to fix it.`,
                    );
                },
                { id: integration },
            );
        }
    },

    'dentally.patient.synced': (msg) => {
        const payload = msg.payload as { patient_id: string };
        store.dispatch(dentally.get(payload.patient_id));
    },

    'patient.lead-source.changed': () => {
        store.dispatch(listSources());
    },

    'patient.business-event.created': ({ identity }) => {
        store.dispatch(fetchTimeline(identity));
    },

    'patient.snooze.waking': (msg) => {
        const payload = msg.payload as { stage: string };
        store.dispatch(
            actionWaking({
                patientId: msg.identity,
                stage: payload.stage,
            }),
        );
    },
});

api.start(() => {
    const state = store.getState();
    return {
        authToken: auth.token(),
        URL: baseURL(state),
        proxyURL: proxyURL(state),
        onAuthError: () => {
            store.dispatch(logout());
            auth.logout();
        },
        setApiVersion: (version) => {
            store.dispatch(setApiVersion(version));
        },
    };
});

versioning.start(() => {
    const state = store.getState();
    return {
        URL: baseURL(state),
        refreshing: refreshing(state),
    };
});

store.subscribe(() => {
    const state = store.getState();

    if (state.config.status !== 'loaded') {
        return;
    }

    const { config } = state.config;

    if (!config) {
        return;
    }

    if (config.environment !== 'development') {
        ReactSentry.init({
            dsn: 'https://a7e2eb4238d84050856bffaa58594834@sentry.io/3731371',
            integrations: [
                new Integrations.Angular(),
                new TracingIntegrations.BrowserTracing(),
            ],
            environment: `${config.environment}:frontend`,
        });
    }

    if (!auth.started()) {
        auth.start(config.api_base_url);
    }
});

ReactSentry.init({
    integrations: [
        new Integrations.Angular(),
        new TracingIntegrations.BrowserTracing(),
    ],
});

auth.onAuthStateChanged(() => {
    if (!auth.authenticated()) {
        store.dispatch(logout());
        return;
    }

    // Send user variables to data layer
    const { sub, client } = auth.payload();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        user_id: sub,
        client_name: client.name,
        created_at: client.created_at,
    });

    // Give some time for Tawk to load
    setTimeout(() => {
        try {
            // Try catch as Tawk may be down and the script
            // wouldn't load, so `Tawk_API` may not be available
            Tawk_API.onLoad = function () {
                Tawk_API.setAttributes(
                    {
                        name: auth.email(),
                        email: auth.email(),
                        version: __VERSION__,
                    },
                    (err) => console.log('Failed to add name to Tawk: ', err),
                );
            };
        } catch (e) {
            // deliberately do nothing here
        }
    }, 2000);
});

declare global {
    interface Window {
        graphlib: typeof graphlib;
        machina: typeof machina;
        redux: typeof redux;
        createLogger: typeof createLogger;
        reduxThunk: typeof reduxThunk;
        phone: typeof phone;
        jQuery: typeof jQuery;
        $: typeof jQuery;
        dataLayer?: any[];
    }
}

window.$ = window.jQuery = jQuery;
window.graphlib = graphlib;
window.machina = machina;
window.redux = redux;
window.createLogger = createLogger;
window.reduxThunk = reduxThunk;
window.phone = phone;

window.angular = angular;

angular
    .module('app', [
        // Dependencies without explicit support
        'bcherny/ngimport',
        'platanus.inflector',
        'utf8-base64',
        '720kb.datepicker',

        // Dependencies with webpack support
        ngRedux,
        // ngUiRouter,
        ngUiBootstrap,
        ngSanitize,
        ngAnimate,
        ngStorage,
        ngUiSelect,
        ngPdf,
        ngTextMask,
        angularModalService,

        // Application code
        legacy,
        v2,
        bridge,
    ])
    .controller('AppController', appController)
    .component('app', appDirective)
    .run(appRunner);

appConfigure(angular);

angular.bootstrap(document.createElement('div'), ['app']);

const AngularApp = angular2react('app', appDirective);

const View = {
    App: AngularApp,
    AddPatient: angular2react('viewAddPatient', views.addPatient),
    ChangeStage: angular2react('viewChangeStage', views.changeStage),
    Client: {
        TxTypes: angular2react('viewClientTxTypes', views.clientTxTypes),
    },
    TxTypes: angular2react('viewTxTypes', views.txTypes),
    Unauthorized: angular2react('viewUnauthorized', views.unauthorized),
};

// Legacy tests fail on this without checking if root element exists
const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<ReactApp View={View} />);
