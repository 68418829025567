import { PatientsQuery } from '$state/types/patients';
import { PatientsResponse } from '$types/patients';
import { ActionsQuery, Patient, ReportQuery } from '@/state/types';
import { Attachment, CommType, PhoneNumber } from '@/types';
import { LegacyV2ActionSchema } from '@/types/legacy';
import { EventPayload as EventPayloadV2 } from '@/types/timeline-v2';
import { api } from './api';
import { TemplateContext } from '$state/types/contexts';
import { axiosRetry } from '$utils';
import { Stage } from '$state/concerns/stages';

type EventType =
    | 'Email'
    | 'Note'
    | 'Form Submission'
    | 'SMS'
    | 'Phone Call'
    | 'Business Event'
    | 'WhatsApp'
    | 'Attachment';

/**
 * We need to model the new API shape here so typescript tells us where exactly
 * we need to map from one format to the other without breaking compatibility
 * with the legacy API, which still heavily depends on the previous format.
 */
interface Event {
    type: EventType;
    id: string;
    datetime: string;
}

interface Comm extends Event {
    text_content: string;
    inbound: boolean;
    status: string;
    read_at?: string;
    attachments?: Attachment[] | null;
}

export interface Email extends Comm {
    type: 'Email';
    from_email: string;
    to_email: string;
    subject: string;
    email_type: CommType.MarketingEmail | CommType.Email;
}

export interface SMS extends Comm {
    type: 'SMS';
    from_number: PhoneNumber;
    to_number: PhoneNumber;
}

export interface Call extends Comm {
    type: 'Phone Call';
    from_number: PhoneNumber;
    to_number: PhoneNumber;
}

export interface WhatsApp extends Comm {
    type: 'WhatsApp';
    from_number: PhoneNumber;
    to_number: PhoneNumber;
}

export interface Note extends Event {
    type: 'Note';
    content: string;
}

export interface FormSubmission extends Event {
    type: 'Form Submission';
    message: string;
    form: string;
    read_at?: string;
}

export interface BusinessEvent extends Event {
    type: 'Business Event';
    headline: string;
    description: string;
    note?: string;
    datetime: string;
    id: string;
    business_event_type: {
        name: string;
        icon: string;
        color: string;
        tooltip?: string;
    };
}

export interface AttachmentEvent extends Event {
    type: 'Attachment';
    comm_type: CommType;
    inbound: boolean;
    attachments: Attachment[];
}

export type EventPayload =
    | Email
    | Note
    | FormSubmission
    | SMS
    | Call
    | BusinessEvent
    | WhatsApp
    | AttachmentEvent;

export interface TimelinePayload {
    items: EventPayload[];
    version?: number;
}

export interface TimelineV2Payload {
    items: EventPayloadV2[];
    version: number;
}

export const getTimeline = (patientId: string): Promise<TimelinePayload> =>
    api.get(`/patients/${patientId}/timeline`).then((res) => res.data);

export const getTimelineV2 = (patientId: string): Promise<TimelineV2Payload> =>
    api.get(`/v2/patients/${patientId}/timeline`).then((res) => res.data);

export const refreshTimeline = (patientId: string): Promise<void> =>
    api.post(`/v3/patients/${patientId}/timeline/fresh`);

export const fetchAttachment = (
    url: string,
    signal?: AbortSignal | undefined,
): Promise<ArrayBuffer> =>
    api
        .get(url, {
            responseType: 'arraybuffer',
            signal: signal,
        })
        .then((res) => res.data);

export interface TakeNoteArgs {
    id: string;
    title?: string;
    content: string;
}

export const takeNote = (
    patientId: string,
    args: TakeNoteArgs,
): Promise<void> => api.post(`/v3/patients/${patientId}/notes`, args);

export const retryComm = (patientId: string, commId: string): Promise<void> =>
    api.post(`/v3/patients/${patientId}/communications/${commId}/retry`);

export type ActionsPayload = LegacyV2ActionSchema[];

const referralsFilterMap = {
    both: null,
    referrals: 1,
    'non-referrals': 0,
} as const;

export const fetchActions = (
    stages?: string[],
    query?: ActionsQuery,
): Promise<ActionsPayload> =>
    api
        .get(`/actions/due`, {
            params: {
                stages: stages,
                referred: referralsFilterMap[query?.referrals ?? 'both'],
                types: query?.types,
                labels: query?.labels,
                sources: query?.sources,
                start_at: query?.range?.start,
                end_at: query?.range?.end,
                include_snoozed:
                    query?.includeSnoozed !== undefined
                        ? Number(query?.includeSnoozed)
                        : undefined,
            },
        })
        .then((res) => res.data);

export interface ActionCountPayload {
    [stage: string]: number;
}

export const actionCounts = async (
    stages: string[],
): Promise<ActionCountPayload> =>
    api
        .get('/v3/actions/counts', {
            params: {
                stages: stages.join(','),
            },
        })
        .then((res) => res.data);

export const fetchPatient = (patientId: string) =>
    api.get(`/v3/patients/${patientId}`).then((res) => res.data);

export const savePatient = (patient: Patient): Promise<void> =>
    api.put(`/v3/patients/${patient.id}`, patient);

export interface NextActionPayload {
    id: string;
    nextAction: string;
    date?: string;
    note?: string;
    source?: string;
    endSnooze?: boolean;
}

export const saveAction = (action: NextActionPayload): Promise<void> => {
    axiosRetry(api, {
        retries: 3,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error) => {
            return error.status === 503;
        },
    });
    return api.put(`/v3/patients/${action.id}/actions`, action);
};

export interface SearchResult {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
}

export const search = (query: string): Promise<SearchResult[]> =>
    api
        .get(`/v3/patients/search`, {
            params: { query },
        })
        .then(({ data }) => data);

export const fetchPatients = (
    query: PatientsQuery & ReportQuery,
    limit: number,
    page: number,
): Promise<PatientsResponse> => {
    const {
        start: from,
        end: to,
        report,
        types,
        sources,
        labels,
        stages,
    } = query;

    const endpoint = report?.startsWith('overview/')
        ? '/v3/patients/overview'
        : '/v3/patients';

    return api
        .get(endpoint, {
            params: {
                report,
                from,
                to,
                types: types || undefined,
                sources: sources || undefined,
                labels: labels || undefined,
                stages: stages || undefined,
                limit,
                page,
            },
        })
        .then(({ data }) => data);
};

export const loadTemplateContext = (
    patientId: string,
): Promise<TemplateContext> =>
    api
        .get(`/v3/patients/${patientId}/template-context`)
        .then(({ data }) => data);

// editable labels
export const unlabel = (patientId: string, label: string): Promise<void> =>
    api.delete(`/patients/${patientId}/labels`, {
        params: {
            label,
        },
    });

export const label = (patientId: string, label: string): Promise<void> =>
    api.put(`/patients/${patientId}/labels`, {
        label,
    });

export const changeStageWithNote = (
    patientId: string,
    stage: Stage,
    note: string | null,
): Promise<void> =>
    api.put(`/v3/patients/${patientId}/stage`, {
        stage: stage.key,
        note,
    });
