import { v4 } from 'uuid';
import {
    assertAuthenticated,
    assertErrored,
    AuthDetails,
    authenticated,
    AuthState,
    email,
    error,
    errored,
    initialState,
    isState,
    JWTPayload,
    login,
    logout,
    parseToken,
    refresh,
    start,
    started,
    token,
} from './lib';
import * as result from '@/utils/result';

const AUTH_STORAGE_KEY = 'leadflo.auth.state';

const listeners: { [key: string]: () => void } = {};

let state: AuthState = initialState;

function invokeListeners() {
    Object.values(listeners).forEach((f) => f());
}

function decodeState(state: string | null): result.Result<AuthState> {
    if (state === null) {
        return result.err(new Error('State does not exist'));
    }

    try {
        const decodedState = JSON.parse(state);

        if (!isState(decodedState)) {
            return result.err(
                new Error('Persisted state is not valid auth state'),
            );
        }

        return result.ok(decodedState);
    } catch (error) {
        return result.err(new Error('Could not parse auth state JSON'));
    }
}

let intervalId: ReturnType<typeof setTimeout>;

async function refreshToken(baseUrl: string): Promise<void> {
    // Gets the Current State from localStorage
    const persistedState = localStorage.getItem(AUTH_STORAGE_KEY);
    const decodedState = decodeState(persistedState);
    if (!decodedState.ok) {
        // Persisted state is invalid so no point keeping it around
        localStorage.removeItem(AUTH_STORAGE_KEY);
        state = start(state, baseUrl, new Date());
        return;
    }
    state = await refresh(decodedState.value);

    if (state.state !== 'authenticated') {
        clearInterval(intervalId);
        state = logout(state);
        localStorage.removeItem(AUTH_STORAGE_KEY);
        return;
    }

    localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(state));
}

function startRefreshInterval(baseUrl: string) {
    if (state.state !== 'authenticated') {
        return;
    }

    intervalId = setInterval(() => {
        refreshToken(baseUrl);
    }, 15 * 60 * 1000);
}

/*
 * The default export is the auth service. This provides a default implementation
 * with state management, allowing the core functions to be expressed and tested
 * in terms of their inputs and return values.
 */
export default {
    reset(): void {
        state = initialState;
        localStorage.removeItem(AUTH_STORAGE_KEY);
    },

    /**
     * Must be called as early in the app as possible. This revives the service
     * from a persisted state, if it exists, otherwise bringing it into a sane
     * starting state.
     *
     * @param baseUrl
     */
    async start(baseUrl: string): Promise<void> {
        const now = new Date();
        const persistedState = localStorage.getItem(AUTH_STORAGE_KEY);
        const decodedState = decodeState(persistedState);

        if (!decodedState.ok) {
            // Persisted state is invalid so no point keeping it around
            localStorage.removeItem(AUTH_STORAGE_KEY);
            state = start(state, baseUrl, now);
            return;
        }

        state = start(decodedState.value, baseUrl, now);

        if (state.state === 'refreshing') {
            return refreshToken(baseUrl).then(() => {
                invokeListeners();
                startRefreshInterval(baseUrl);
            });
        }

        invokeListeners();
        startRefreshInterval(baseUrl);
    },

    async login(details: AuthDetails): Promise<void> {
        state = await login(state, details);
        localStorage.setItem(AUTH_STORAGE_KEY, JSON.stringify(state));
        if (state.state !== 'authenticated') {
            return;
        }
        startRefreshInterval(state.baseUrl);
        invokeListeners();
    },

    logout(): void {
        state = logout(state);
        localStorage.removeItem(AUTH_STORAGE_KEY);
        invokeListeners();

        if (intervalId) {
            clearInterval(intervalId);
        }
    },

    started(): boolean {
        return started(state);
    },

    authenticated(): boolean {
        return authenticated(state);
    },

    errored(): boolean {
        return errored(state);
    },

    error(): string {
        assertErrored(state);
        return error(state);
    },

    token(): string {
        assertAuthenticated(state);
        return token(state);
    },

    email(): string {
        assertAuthenticated(state);
        return email(state);
    },

    payload(): JWTPayload {
        assertAuthenticated(state);
        return parseToken(token(state));
    },

    onAuthStateChanged(f: () => void): () => void {
        const listenerId = v4();
        listeners[listenerId] = f;
        return () => {
            delete listeners[listenerId];
        };
    },
};
