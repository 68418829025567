import React, { ChangeEventHandler } from 'react';
import { useForm, Controller, Control } from 'react-hook-form';
import {
    useRadioGroup,
    useRadio,
    useTextField,
    useId,
    VisuallyHidden,
} from 'react-aria';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { parseDate } from 'chrono-node';
import { NextAction, NextActionContext, nextActions } from '$ui/AddPatient/fsm';
import { Footer } from '$ui/AddPatient/Footer';

export type NextActionProps = NextActionContext & {
    onContinue: (data: NextActionContext) => void;
    onBack: () => void;
};

const actionLabels: Record<NextAction, string> = {
    enquiry: "Follow up the patient's enquiry",
    thinkingConsult: 'Continue discussions about booking a consultation',
    booking: 'Book in for a consultation',
    thinkingTx: 'Chase them about a treatment decision',
    inTx: 'Book in for a treatment appointment',
};

const dateLabels: Record<NextAction, string> = {
    enquiry: 'When do you want to contact them?',
    thinkingConsult: 'When do you want to next contact them?',
    booking: 'When is their consultation?',
    thinkingTx: 'When do you want to next contact them?',
    inTx: 'When do you want to next update their treatment progress?',
};

export const NextActions: React.FC<NextActionProps> = ({
    onContinue,
    onBack,
    ...defaultValues
}) => {
    const { control, handleSubmit, watch } = useForm<NextActionContext>({
        defaultValues,
    });

    const nextAction = watch('action');

    return (
        <Container>
            <ScrollableContent>
                <Form onSubmit={handleSubmit(onContinue)}>
                    <Controller
                        name="action"
                        control={control}
                        render={({ field }) => (
                            <NextActionSelector
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                    <ScheduleForm
                        key={nextAction}
                        control={control}
                        nextAction={nextAction}
                    />
                </Form>
            </ScrollableContent>
            <Footer
                id="nextAction"
                onContinue={handleSubmit((data) => onContinue(data))}
                onBack={onBack}
            />
        </Container>
    );
};

const NextActionSelector: React.FC<{
    value: NextAction;
    onChange: (value: NextAction) => void;
}> = (props) => {
    const { value } = props;

    const onChange = (value: string) => {
        if (!nextActions.includes(value as NextAction)) {
            throw new Error(`Unexpected next action: '${value}'`);
        }

        props.onChange(value as NextAction);
    };

    const state = useRadioGroupState({ value, onChange });

    const { radioGroupProps } = useRadioGroup(
        {
            'aria-label': 'What happened with this patient?',
            value,
            onChange,
        },
        state,
    );

    return (
        <RadioGroup {...radioGroupProps}>
            <RadioGroupLabel>What happened with this patient?</RadioGroupLabel>
            <RadioButtonList>
                {Object.entries(actionLabels).map(([action, label]) => (
                    <RadioButton
                        key={action}
                        state={state}
                        value={action as NextAction}
                        label={label}
                        isSelected={value === action}
                        onChange={onChange}
                    />
                ))}
            </RadioButtonList>
        </RadioGroup>
    );
};

const RadioButton: React.FC<{
    value: NextAction;
    state: RadioGroupState;
    label: string;
    isSelected: boolean;
    onChange: (value: NextAction) => void;
}> = ({ value, state, label, isSelected, onChange }) => {
    const ref = React.useRef(null);
    const { inputProps } = useRadio({ value, 'aria-label': label }, state, ref);

    return (
        <RadioButtonWrapper isSelected={isSelected}>
            <VisuallyHidden>
                <input {...inputProps} onChange={() => onChange(value)} />
            </VisuallyHidden>
            <RadioButtonLabel>{label}</RadioButtonLabel>
        </RadioButtonWrapper>
    );
};

const ScheduleForm: React.FC<{
    control: Control<NextActionContext>;
    nextAction: NextAction;
}> = ({ control, nextAction }) => {
    return (
        <ScheduleFormWrapper>
            <Controller
                name="date"
                control={control}
                render={({ field }) => {
                    return (
                        <DateField
                            label={dateLabels[nextAction]}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    );
                }}
            />
            <Controller
                name="note"
                control={control}
                render={({ field }) => (
                    <NoteField
                        label="Is there anything to note about this patient?"
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </ScheduleFormWrapper>
    );
};

const parseDateText = (text: string): string | undefined => {
    const date = parseDate(text);

    if (!date) {
        return;
    }

    const [datePart] = date.toISOString().split('T');
    return datePart;
};

const DateField: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
}> = ({ label, value, onChange }) => {
    const id = useId();

    const onDatePicked: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange(e.target.value);
    };

    const onTextEntered: ChangeEventHandler<HTMLInputElement> = (e) => {
        const date = parseDateText(e.target.value);

        if (!date) {
            return;
        }

        onChange(date);
    };

    return (
        <FieldWrapper>
            <Label htmlFor={id}>{label}</Label>
            <DateInputWrapper>
                <DateInput
                    placeholder="e.g 'in 2 weeks'"
                    onChange={onTextEntered}
                />
                <DateInput
                    id={id}
                    type="date"
                    value={value}
                    onChange={onDatePicked}
                />
            </DateInputWrapper>
        </FieldWrapper>
    );
};

const NoteField: React.FC<{
    label: string;
    value: string;
    onChange: (value: string) => void;
}> = ({ label, value, onChange }) => {
    const ref = React.useRef(null);

    const { inputProps, labelProps } = useTextField(
        {
            label,
            value,
            onChange,
            inputElementType: 'textarea',
        },
        ref,
    );

    return (
        <FieldWrapper>
            <Label {...labelProps}>{label}</Label>
            <NoteInput {...inputProps} placeholder="e.g pt is on holiday atm" />
        </FieldWrapper>
    );
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const ScrollableContent = styled.div`
    flex: 1;
    overflow-y: auto;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    ${mix.gap({ size: 3 })};
`;

const RadioGroup = styled.div`
    ${mix.padding({ padding: [3, 3, 0] })};
    ${mix.gap({ size: 2 })};
    display: flex;
    flex-direction: column;
`;

const RadioGroupLabel = styled.div`
    ${mix.type({ level: 'body2', bold: true })};
    color: var(--gray-600);
    font-size: 16px;
`;

const RadioButtonList = styled.div`
    ${mix.gap({ size: 0.5 })};
    display: flex;
    flex-direction: column;
`;

const RadioButtonWrapper = styled.label<{ isSelected: boolean }>`
    ${mix.padding({ padding: 1 })};
    ${mix.height({ size: 4.5 })};
    display: flex;
    align-items: center;
    line-height: 1;
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    background-color: ${(props) =>
        props.isSelected ? 'var(--primary-100)' : 'var(--white)'};
    border-color: ${(props) =>
        props.isSelected ? 'var(--primary-100)' : 'var(--gray-300)'};
    cursor: pointer;

    &:hover {
        background-color: ${(props) =>
            props.isSelected ? 'var(--primary-100)' : 'var(--gray-050)'};
    }

    input {
        margin-right: ${mix.unit({ size: 1 })};
    }
`;

const RadioButtonLabel = styled.span`
    ${mix.type({ level: 'body2' })};
    color: var(--gray-600);
`;

const ScheduleFormWrapper = styled.div`
    ${mix.padding({ padding: 3 })};
    ${mix.gap({ size: 3 })};
    border-top: 1px solid var(--gray-100);
    display: flex;
    flex-direction: column;
`;

const FieldWrapper = styled.div`
    ${mix.gap({ size: 1 })};
    display: flex;
    flex-direction: column;
`;

const Label = styled.label`
    ${mix.type({ level: 'body2', bold: true })};
    color: var(--gray-600);
`;

const DateInputWrapper = styled.div`
    ${mix.gap({ size: 0.5 })};
    display: flex;
    align-items: center;
`;

const DateInput = styled.input`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 1.5 })};
    ${mix.height({ size: 5.5 })};
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    flex: 1 0;
    font-size: 14px;
    color: var(--gray-600);
    min-width: 0;

    ::placeholder {
        color: var(--gray-400);
    }
`;

const NoteInput = styled.textarea`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 1.5 })};
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
    font-size: 14px;
    color: var(--gray-600);

    ::placeholder {
        color: var(--gray-400);
    }
`;
